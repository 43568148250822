import { useContext, useEffect, useState } from "react";
import { Select, Option,Button, Spinner } from "@material-tailwind/react";
import HeroBG from "../assets/images/pexels-angele-j-128402.jpg";
import {default as Typehead  } from "react-tailwindcss-select";
import { CuisinesData,DietData,IntolerancesData } from "../data";
import recipes from "../API/recipes";
import { RecepiesContext } from "../context/RepiesContext";
import { focusSearch } from "../utils";

const Hero = () => {
  const [data, setData] = useState({cuisine:undefined,diet:undefined,intolerances:undefined});
  const [loading,setLoading]=useState(false);
  const {setRecepies}=useContext(RecepiesContext);
  
  useEffect(()=>{
    recipes.getRecepies()
    .then(res=>setRecepies({...res.data,...data}))
    .catch(err=>{console.log(err)})
    .finally(()=>setLoading(false))
    // eslint-disable-next-line
  },[setRecepies])
  

  const setCuisines=(text)=>setData(prev=>({...prev,cuisine:text}))
  const setDiet=(text)=>setData(prev=>({...prev,diet:text}))
  const setIntolerances=(values)=>setData(prev=>({...prev,intolerances:values}))

  const handleSubmit=(e)=>{
    setLoading(true)
    e.preventDefault();
    recipes.getRecepies(data)
    .then(res=>{
      setRecepies({...res.data,...data})
      focusSearch()
    })
    .catch(err=>{console.log(err)})
    .finally(()=>setLoading(false))
  }
  
  const handleClear=(e)=>{
    e.preventDefault();
    setData({cuisine:undefined,diet:undefined,intolerances:undefined})
  }
  return (
    <div
      style={{
        background: `linear-gradient(0deg, #000000a7 , #0000009a),url('${HeroBG}') no-repeat`,
        backgroundSize: "cover",
      }}
      className="min-h-[500px]  h-[calc(90vh-50px)]  bg-cover  w-full bg-blue-gray-900 flex justify-center items-center"
    >
      <div className="p-2 px-5 sm:px-10  bg-[#171515b8] h-max ">
        <div className="py-3">
          <Select className="min-w-[100%] sm:w-[300px] text-white" name="cuisine" value={data.cuisine} onChange={setCuisines} label="Select Cuisines">
           {CuisinesData.map((cuisine)=><Option value={cuisine}>{cuisine}</Option>)} 
          </Select>
        </div>
        
        <div className="py-3">
          <Select className="min-w-[100%] sm:w-[300px] text-white" name="diet" value={data.diet} onChange={setDiet} label="Select Diet" >
           {DietData.map((diet)=><Option value={diet}>{diet}</Option>)}
          </Select>
        </div>
       
        <div className="py-3">
          <Typehead
           classNames={{
            ChevronIcon: ({ isDisabled }) => (
                `bg-red-900 w-[100px]`
            ),
            tagItem:()=>('bg-white flex p-1 rounded-[7px] text-black'),
            menuButton: ({ isDisabled }) => (
                 "flex justify-between peer h-full bg-transparent font-sans font-normal text-left outline outline-0 focus:outline-0 disabled:bg-blue-gray-50 disabled:border-0 transition-all border text-sm px-3 rounded-[7px] border-blue-gray-200 w-[300px] text-white"
            ),
           }
           }
            placeholder="Select Intolerance"
            value={data.intolerances}
            isMultiple={true}
            onChange={setIntolerances}
            options={IntolerancesData.map(intolerence=>({value:intolerence,label:intolerence}))}
          />
        </div>
        <div className="py-3 flex">
        <Button onClick={handleSubmit} disabled={loading} className="bg-white text-black m-auto block " >
         {loading?<Spinner/>:'Search'} 
        </Button>
        <Button onClick={handleClear} disabled={loading} className="bg-white text-black m-auto block " >
         Clear
        </Button>
        </div>
      </div>
    </div>
  );
};

export default Hero;
