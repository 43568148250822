import Hero from "../components/Hero";
import Header from "../components/Navbar"
import Suggestion from "../components/Suggestion";
import SearchResult from "../components/SearchResult";
import { SimpleFooter } from "../components/Fotter";
import { useState } from "react";
import { RecepiesContext } from "../context/RepiesContext";
const Main=()=>{
    const [recepies,setRecepies]=useState([]);

    return(
    <RecepiesContext.Provider value={{recepies,setRecepies}}>
    <Header/>
    <Hero/>
    <Suggestion/>
    <SearchResult/>
    <SimpleFooter/>
    </RecepiesContext.Provider>)
}
export default Main;